var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('v-card-title',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","color":"primary"},on:{"click":_vm.routeToNewQuestions}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" neue Frage ")],1)],1),_c('v-card-title',[_vm._v(" Fragen "),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Suchen","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"search":_vm.search,"custom-filter":_vm.customSearch,"headers":_vm.headers,"items":_vm.questions,"item-key":"number","multi-sort":""},scopedSlots:_vm._u([{key:"item.de",fn:function(ref){
var item = ref.item;
return [(!item.de)?_c('div',{staticClass:"d-flex align-center"},[_c('v-chip',{attrs:{"color":"warning","label":"","small":"","outlined":""}},[_vm._v(" Nicht vorhanden ")])],1):_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":""},on:{"click":function($event){return _vm.routeTOeditQuestions(item.number)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Bearbeiten")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-pdf-box")])],1)]}}],null,true)},[_c('span',[_vm._v("PDF")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Löschen")])])],1)]}},{key:"item.fr",fn:function(ref){
var item = ref.item;
return [(!item.fr)?_c('div',{staticClass:"d-flex align-center"},[_c('v-chip',{attrs:{"color":"warning","label":"","small":"","outlined":""}},[_c('v-icon',{attrs:{"small":"","left":"","warning":""}}),_vm._v(" Nicht vorhanden ")],1)],1):_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Bearbeiten")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-pdf-box")])],1)]}}],null,true)},[_c('span',[_vm._v("PDF")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Löschen")])])],1)]}},{key:"item.it",fn:function(ref){
var item = ref.item;
return [(!item.it)?_c('div',{staticClass:"d-flex align-center"},[_c('v-chip',{attrs:{"color":"warning","label":"","small":"","outlined":""}},[_c('v-icon',{attrs:{"small":"","left":"","warning":""}}),_vm._v(" Nicht vorhanden ")],1)],1):_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Bearbeiten")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-pdf-box")])],1)]}}],null,true)},[_c('span',[_vm._v("PDF")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Löschen")])])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'Definitiv')?[_c('v-chip',{attrs:{"color":"success","label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-check ")]),_vm._v(" "+_vm._s(item.status)+" ")],1)]:[_c('v-chip',{attrs:{"color":"warning","label":"","small":"","text-color":"white"}},[_vm._v(" "+_vm._s(item.status)+" ")])]]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""},on:{"click":function($event){return _vm.copyRow(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Kopieren")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":""},on:{"click":_vm.routeToQuestionsComents}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-comment-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Kommentar eingeben")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""},on:{"click":function($event){return _vm.routeToQuestionsHistory()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-history")])],1)]}}],null,true)},[_c('span',[_vm._v("Protokoll")])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }