<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title class="d-flex justify-end">
          <v-btn @click="routeToNewQuestions" class="ma-2" dark color="primary">
            <v-icon>mdi-plus</v-icon>
            neue Frage
          </v-btn>
        </v-card-title>
        <v-card-title>
          Fragen
          <v-spacer />
          <v-spacer />
          <v-spacer />
          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Suchen"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          :search="search"
          :custom-filter="customSearch"
          :headers="headers"
          :items="questions"
          item-key="number"
          class="elevation-1 table-one"
          multi-sort
        >
          <template v-slot:item.de="{ item }">
            <div v-if="!item.de" class="d-flex align-center">
              <v-chip color="warning" label small outlined>
                Nicht vorhanden
              </v-chip>
            </div>
            <div v-else class="d-flex align-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="routeTOeditQuestions(item.number)"
                    color="success"
                    dark
                    v-bind="attrs"
                    icon
                    v-on="on"
                  >
                    <v-icon left>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>Bearbeiten</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="danger" dark v-bind="attrs" icon v-on="on">
                    <v-icon left>mdi-file-pdf-box</v-icon>
                  </v-btn>
                </template>
                <span>PDF</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="danger" dark v-bind="attrs" icon v-on="on">
                    <v-icon left>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Löschen</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.fr="{ item }">
            <div v-if="!item.fr" class="d-flex align-center">
              <v-chip color="warning" label small outlined>
                <v-icon small left warning></v-icon>
                Nicht vorhanden
              </v-chip>
            </div>
            <div v-else class="d-flex align-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="success" dark v-bind="attrs" icon v-on="on">
                    <v-icon left>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>Bearbeiten</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="danger" dark v-bind="attrs" icon v-on="on">
                    <v-icon left>mdi-file-pdf-box</v-icon>
                  </v-btn>
                </template>
                <span>PDF</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="danger" dark v-bind="attrs" icon v-on="on">
                    <v-icon left>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Löschen</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.it="{ item }">
            <div v-if="!item.it" class="d-flex align-center">
              <v-chip color="warning" label small outlined>
                <v-icon small left warning></v-icon>
                Nicht vorhanden
              </v-chip>
            </div>
            <div v-else class="d-flex align-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="success" dark v-bind="attrs" icon v-on="on">
                    <v-icon left>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>Bearbeiten</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="danger" dark v-bind="attrs" icon v-on="on">
                    <v-icon left>mdi-file-pdf-box</v-icon>
                  </v-btn>
                </template>
                <span>PDF</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="danger" dark v-bind="attrs" icon v-on="on">
                    <v-icon left>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Löschen</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.status="{ item }">
            <template v-if="item.status === 'Definitiv'">
              <v-chip color="success" label small text-color="white">
                <v-icon small left> mdi-check </v-icon>
                {{ item.status }}
              </v-chip>
            </template>
            <template v-else>
              <v-chip color="warning" label small text-color="white">
                {{ item.status }}
              </v-chip>
            </template>
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="copyRow(item)"
                  color="primary"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>Kopieren</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="routeToQuestionsComents"
                  color="success"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>mdi-comment-outline</v-icon>
                </v-btn>
              </template>
              <span>Kommentar eingeben</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="routeToQuestionsHistory()"
                  color="primary"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>mdi-history</v-icon>
                </v-btn>
              </template>
              <span>Protokoll</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "QuestionsList",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "QuestionsList",
  },
  methods: {
    routeToQuestionsHistory() {
      this.$router.push({ name: "questionsHistory" });
    },
    routeToQuestionsComents() {
      this.$router.push({ name: "questionsComments" });
    },
    routeToNewQuestions() {
      this.$router.push({ name: "newQuestions" });
    },
    routeTOeditQuestions(Ai_id) {
      this.$router.push({ name: "editQuestions", params: { id: Ai_id } });
    },
    customSearch(value, search, item) {
      return Object.values(item).some(
        (v) =>
          (v && v.toString().toLowerCase().includes(search.toLowerCase())) ||
          v.toString().toUpperCase().includes(search.toUpperCase())
      );
    },
    copyRow: function (Ao_Frage) {
      //console.log(Ao_Frage);
      this.questions.push(Ao_Frage);
    },
  },
  data() {
    return {
      newQuestion: [
        {
          number: "",
          description: "",
          type: "",
          de: "",
          fr: "",
          it: "",
          status: "",
          keyWords: [""],
        },
      ],
      language: [""],
      search: "",
      headers: [
        {
          text: "Nummer",
          align: "start",
          sortable: false,
          value: "number",
        },
        { text: "Typ", value: "type" },
        { text: "Bezeichnung", value: "description" },
        { text: "De", value: "de" },
        { text: "Fr", value: "fr" },
        { text: "IT", value: "it" },
        { text: "Status", value: "status" },
        { text: "Aktionen", value: "action" },
      ],
      questions: [
        {
          number: "111001",
          description: "Baustellenkontrolle",
          type: "3_E_111001",
          de: "95",
          fr: "$14,384",
          it: "03/24/2020",
          status: "Definitiv",
          keyWords: ["frau", "küche"],
        },
        {
          img: require("@/assets/images/avatars/002-woman.svg"),
          number: "114001",
          description: "Absturzsicherung",
          type: "3_E_111005",
          de: "876",
          fr: "",
          it: "03/24/2020",
          status: "Entwurf",
        },
        {
          img: require("@/assets/images/avatars/003-man-1.svg"),
          number: "111801",
          description: "Höhensicherung",
          type: "3_E_111011",
          de: "300",
          fr: "$78,384",
          it: "",
          status: "Definitiv",
        },
        {
          img: require("@/assets/images/avatars/004-bald.svg"),
          number: "118001",
          description: "Staubbelastung",
          type: "3_E_111077",
          de: "700",
          fr: "$78,384",
          it: "03/24/2020",
          status: "QSK",
        },
        {
          img: require("@/assets/images/avatars/007-woman-2.svg"),
          number: "181001",
          description: "Staubbelastung",
          type: "3_E_111077",
          de: "",
          fr: "$78,384",
          it: "03/24/2020",
          status: "In Übersetzung",
        },
        {
          img: require("@/assets/images/avatars/001-man.svg"),
          number: "171097",
          description: "Baustellenkontrolle",
          type: "3_E_111001",
          de: "95",
          fr: "$14,384",
          it: "03/24/2020",
          status: "Entwurf",
        },
        {
          img: require("@/assets/images/avatars/002-woman.svg"),
          number: "611001",
          description: "Absturzsicherung",
          type: "3_E_111005",
          de: "876",
          fr: "$38,384",
          it: "03/24/2020",
          status: "Definitiv",
        },
        {
          img: require("@/assets/images/avatars/003-man-1.svg"),
          number: "116501",
          description: "Höhensicherung",
          type: "3_E_111011",
          fr: "$78,384",
          it: "03/24/2020",
          status: "QSK",
        },
        {
          img: require("@/assets/images/avatars/004-bald.svg"),
          number: "115401",
          description: "Staubbelastung",
          type: "3_E_111077",
          de: "700",
          fr: "$78,384",
          it: "03/24/2020",
          status: "Definitiv",
        },
        {
          img: require("@/assets/images/avatars/007-woman-2.svg"),
          number: "101097",
          description: "Staubbelastung",
          type: "3_E_111077",
          de: "700",
          fr: "$78,384",
          it: "03/24/2020",
          status: "In Übersetzung",
        },
        {
          img: require("@/assets/images/avatars/002-woman.svg"),
          number: "111991",
          description: "Absturzsicherung",
          type: "3_E_111005",
          de: "876",
          fr: "$38,384",
          it: "03/24/2020",
          status: "In Übersetzung",
        },
        {
          img: require("@/assets/images/avatars/003-man-1.svg"),
          number: "113201",
          description: "Höhensicherung",
          type: "3_E_111011",
          de: "300",
          fr: "$78,384",
          it: "03/24/2020",
          status: "QSK",
        },
        {
          img: require("@/assets/images/avatars/004-bald.svg"),
          number: "193201",
          description: "Staubbelastung",
          type: "3_E_111077",
          de: "700",
          fr: "$78,384",
          it: "03/24/2020",
          status: "Definitiv",
        },
        {
          img: require("@/assets/images/avatars/007-woman-2.svg"),
          number: "116201",
          description: "Staubbelastung",
          type: "3_E_111077",
          de: "700",
          fr: "$78,384",
          it: "03/24/2020",
          status: "In Übersetzung",
        },
        {
          img: require("@/assets/images/avatars/001-man.svg"),
          number: "113204",
          description: "Baustellenkontrolle",
          type: "3_E_111001",
          de: "95",
          fr: "$14,384",
          it: "03/24/2020",
          status: "Entwurf",
        },
        {
          img: require("@/assets/images/avatars/002-woman.svg"),
          number: "113406",
          description: "Absturzsicherung",
          type: "3_E_111005",
          de: "876",
          fr: "$38,384",
          it: "03/24/2020",
          status: "QSK",
        },
        {
          img: require("@/assets/images/avatars/003-man-1.svg"),
          number: "133201",
          description: "Höhensicherung",
          type: "3_E_111011",
          de: "300",
          fr: "$78,384",
          it: "03/24/2020",
          status: "Definitiv",
        },
        {
          img: require("@/assets/images/avatars/004-bald.svg"),
          number: "1883001",
          description: "Staubbelastung",
          type: "3_E_111077",
          de: "700",
          fr: "$78,384",
          it: "03/24/2020",
          status: "In Übersetzung",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }
    }
  }
}
</style>
